import { useState, useEffect } from "react";
import { getGroupMembers } from "../../libs/dataFunctions";
import Spinner from "../Spinner/Spinner";

const MemberWithGroupInput = ({teamID, token, parentsGroups, selectedMembers, onChange, onChangeMain}) =>{
    const [showMembers, setShowMembers] = useState(false);
    const [editOptions, setEditOptions] = useState([]);
    const [editSelectedMembers, setEditSelectedMembers] = useState([]);
    
    useEffect(()=>{
        setEditSelectedMembers(selectedMembers);
    },[]);

    return(
        <>
        {editSelectedMembers.length>0 ?
            <div className='virtual-text-field !px-4' onClick={()=>setShowMembers(!showMembers)}>
                {editSelectedMembers.map((scoach)=>{
                    return(
                        <span key={scoach.sub}>{scoach.name}</span>
                    )
                })}
            </div>
        :
            <div className='virtual-text-field !px-4' onClick={()=>setShowMembers(!showMembers)}>Select Members</div>
        }
        {showMembers &&
            <MembersInputPopup 
                setShowForm={setShowMembers} 
                setEditOptions={setEditOptions} 
                setEditSelectedMembers={setEditSelectedMembers}
                editSelectedMembers={editSelectedMembers}
                editOptions={editOptions}
                onChange={onChange}
                onChangeMain={onChangeMain}
                teamID={teamID}
                token={token}
                parentsGroups={parentsGroups}
            />
        }
        </>
    )
}
export default MemberWithGroupInput;

const MembersInputPopup = ({setShowForm, setEditOptions, setEditSelectedMembers,  editSelectedMembers, editOptions, onChange, onChangeMain, teamID, token, parentsGroups}) =>{
    const [loading, setLoading] = useState(false);
    const [searchParam] = useState(["name"]);
    const [groupBy, setGroupBy] = useState(parentsGroups[0].value);
    const [q, setSearchQuery] = useState("");
    const [showGroupsFilter, setShowGroupFilter] = useState(false);
    
    console.log(parentsGroups);

    const handleUpdateMembers=()=>{
        onChange(editSelectedMembers);
        onChangeMain(editOptions);
        setShowForm(false);
    }

    const fetcMembers=async()=>{
        setLoading(true);
        const [teamMemberData] = await Promise.all([
            getGroupMembers(teamID, token, groupBy),
        ]);
        
        teamMemberData.forEach((tmMember, index)=>{
            const found = editSelectedMembers.some(el => el.sub === tmMember.sub);
            if(found){
                var foundIndex = editSelectedMembers.findIndex(x=> x.sub === tmMember.sub);
                tmMember.role=editSelectedMembers[foundIndex].role;
                tmMember.custom_role=editSelectedMembers[foundIndex].custom_role;
            }
        });

        setEditOptions(teamMemberData);
        setLoading(false);
    }

    useEffect(()=>{
        fetcMembers();
    },[groupBy]);

    const search=(items)=>{
        return items.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]?
                        item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                    :
                    null
                );
            });
        });
    }

    return(
        <div className="popup-form">
            <div className="popup-form-wrap relative !px-2.5 sm:!px-8 !max-w-[500px] !w-full">
                <button type="button" className="close-popup" onClick={(e) => { setShowForm(false) }}><span>close</span></button>
                <div className="form-heading text-center mb-[30px]">Select Member</div>
                <div className="search-form-wrap flex mb-5 relative">
                    <label htmlFor="search-form" className="search-field relative flex-1 mr-2.5">
                        <input
                            type="search"
                            name="search-field"
                            id="search-form"
                            className="search-input"
                            placeholder="Search Now"
                            value={q}
                            onChange={(e) => {
                                setSearchQuery(e.target.value);                                                            
                            }}
                        />
                    </label>
                    <button type="button" className={`button-icon button-icon-sort`} onClick={(e)=>{setShowGroupFilter(!showGroupsFilter);}}><span>Filter</span></button>
                    <ul className={`sort-actions absolute overflow-hidden rounded-lg bg-white top-full right-0 !min-w-[250px] ${!showGroupsFilter ? 'hidden' : ''}`}>
                        {parentsGroups.map((pg, index)=>{
                            return(
                                <li className="hover:!bg-white" key={index}>
                                    <button type="button" className={`cursor-pointer w-full text-left ${groupBy===pg.value ? 'text-[var(--color-darkblue)] font-bold' : ''}`} onClick={()=>{setGroupBy(pg.value); setShowGroupFilter(false);}}>{pg.label}</button>
                                    {pg.children!==undefined && pg.children.length>0 &&
                                    <>
                                        <ul className="ml-4">
                                        {pg.children.map((pgc,index2)=>{
                                            return(
                                                <li className="hover:!bg-white" key={index2}>
                                                    <button type="button" className={`cursor-pointer w-full text-left ${groupBy===pgc.value ? 'text-[var(--color-darkblue)] font-bold' : ''}`} onClick={()=>{setGroupBy(pgc.value); setShowGroupFilter(false);}}>{pgc.label}</button>
                                                </li>
                                            )
                                        })}
                                        </ul>
                                    </>
                                    }
                                </li>
                            )
                        })}
                    </ul>
                </div>
                {!loading ?
                    <div className="options">
                        {search(editOptions).map((option, index)=>{
                            return(
                                <>
                                <MemberOptionRow key={index} rowOption={option} setEditSelectedMembers={setEditSelectedMembers} editSelectedMembers={editSelectedMembers} />
                                </>
                            )
                        })}
                    </div>
                :
                    <Spinner />
                }
                <div className="flex justify-center">
                    <button className="button button-medium !mx-auto" type="button" onClick={()=>handleUpdateMembers()}>DONE</button>
                </div>
            </div>
        </div>
    );
}

const MemberOptionRow=({rowOption, setEditSelectedMembers, editSelectedMembers})=>{
    const [selected,setSelected] = useState(false);
    const found = editSelectedMembers.some(el => el.sub === rowOption.sub);    
    const handleOnChange=(e)=>{
        var updatedList = [...editSelectedMembers];
        setSelected(e.target.checked);
        if(e.target.checked){
            updatedList = [...editSelectedMembers, {'name':rowOption.name, 'sub':rowOption.sub}];
            setEditSelectedMembers(updatedList);
        } 
        else{
            var newList = updatedList.filter(editSelectedMembers=> editSelectedMembers.sub !== rowOption.sub);
            setEditSelectedMembers(newList);
        }
    }

    useEffect(()=>{        
        if(found){
            setSelected(true);
        }
    },[]);

    return(
        <>
        <div className="option-row pb-2.5 border-b border-slate-500 mb-2.5 last:mb-0 last:border-b-0">
            <label htmlFor={`label-${rowOption.sub}`} className="flex justify-between cursor-pointer">
                <span className="flex-1 mr-2.5 text-[#363F6C] font-medium">{rowOption.name}</span>
                <input id={`label-${rowOption.sub}`} type="checkbox" checked={selected} onChange={(e)=>{handleOnChange(e)}} />
            </label>            
        </div>
        </>
    )
}