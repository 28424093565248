import React, { useEffect, useState, useContext, useRef } from 'react';
import { useParams, NavLink, useNavigate } from 'react-router-dom';
import * as DOMPurify from 'dompurify';
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import revents from '../../../assets/images/noevents.png';
import IsMobile from "../../../components/isMobile";
import AdminNavbar from "../../../components/AdminNavbar/AdminNavbar";
import Sidebar from "../../../components/Sidebar/Sidebar";
import ResponseRow from './ResponseRow';
import AttendeeRow from './AttendeeRow';
//import Advertise from '../../../assets/images/ad.png';
import Spinner from '../../../components/Spinner/Spinner';
import ResponseForm from './ResponseForm';
import PlaiMap from '../../../components/Map/Map';
import { getEventDetail } from '../../../libs/dataFunctions';
import Comments from '../../../components/Comments/Comments';
import { TeamContext, TeamContextProvider } from '../../../contexts/TeamContext';
import DocumentView from '../../../components/DocumentView/DocumentView';
import ManualAttendiForm from './ManualAttendiForm';
import NotRespondedList from './NotRespondedList';
import CoachItem from './CoachItem';
import EventSeenBy from './EventSeenBy';
import JsFileDownloader from 'js-file-downloader';
import LiveStreamFormPopup from './LiveStreamFormPopup';
import WatchLiveImg from "../../../assets/images/watch_live.png";
import LiveScoreCard from './LiveScoreCard';

dayjs.extend(utc);
dayjs.extend(tz);

const EventView = ({ id }) => {
    const { team, teamUpdating } = useContext(TeamContext);
    const [loading, setLoading] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);
    const [eventItem, setEventItem] = useState([]);
    const [tabActive, setTabActive] = useState('overview');
    const ismobile = IsMobile(window);
    const [openResponseForm, setShowResponseForm] = useState(false);
    const [showAddToCalendar, setAddToCalendar] = useState(false);
    const [showMoreOpt, setShowMoreOpt] = useState(false);
    const [openSeenBy,setOpenSeenBy] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [liveStreamLinkform, setLiveStreamLinkForm] = useState(false);
    const navigate = useNavigate();

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token') !== undefined
        ? localStorage.getItem('auth_token')
        : '';
    const ref = useRef();
    async function refreshEvent() {
        setLoading(true);
        var eventData = await getEventDetail(id, team._id, token);
        setEventItem(eventData);
        setLoading(false);
    };

    useEffect(() => {
        if (!teamUpdating) {
            refreshEvent();
        }
    }, [teamUpdating]);

    const ManualAttendi = ({ setShowForm }) => {
        return (
            <div className="popup-form">
                <div className="popup-form-wrap relative !px-7.5 !w-full !max-w-[560px]">
                    <button type="button" className="close-popup" onClick={(e) => { setShowForm(false) }}><span>close</span></button>
                    <ManualAttendiForm setShowForm={setShowForm} team={team} refreshEvent={refreshEvent} eventItem={eventItem} />
                </div>
            </div>
        )
    }

    const NoRespondedList = ({ setShowForm }) => {
        return (
            <div className="popup-form">
                <div className="popup-form-wrap relative !px-5 !w-full !max-w-[800px]">
                    <button type="button" className="close-popup" onClick={(e) => { setShowForm(false) }}><span>close</span></button>
                    <NotRespondedList setShowForm={setShowForm} team={team} eventItem={eventItem} />
                </div>
            </div>
        )
    }

    const TabsMenu = ({ tabActive, setTabActive, ismobile, responses, attendance }) => {
        let tabmenuItems = [
            {
                name: 'Overview',
                slug: 'overview',
                class: tabActive === 'overview' ? 'active' : '',
                hide: false,
            },
            {
                name: 'Responses',
                slug: 'responses',
                class: tabActive === 'responses' ? 'active' : '',
                hide: ((eventItem.user_role === 'admin' || eventItem.user_role === 'group_admin')) ? false : true,
            },
            {
                name: 'Attendance',
                slug: 'attendance',
                class: tabActive === 'attendance' ? 'active' : '',
                hide: ((eventItem.user_role === 'member' && attendance !== undefined && attendance.length > 0) || eventItem.user_role === 'admin' || eventItem.user_role === 'group_admin') ? false : true,
            },
        ]
        return (
            <ul className="tabs-menu flex">
                {tabmenuItems.map((el, id) => {
                    return (
                        !el.hide &&
                        <li key={id} className={el.class} onClick={() => {
                            setTabActive(el.slug)
                        }}>{el.name}
                        </li>
                    )
                })}
            </ul>
        );
    }

    const TabEventOverview = (props) => {
        let location = null;
        if (eventItem.location !== "" && eventItem.latitude !== "" && eventItem.longitude !== "") {
            location = {
                address: eventItem.location,
                lat: eventItem.latitude,
                lng: eventItem.longitude,
            }
        }

        async function deletDocument(docfile) {
            if (window.confirm("Are you sure you want to delete this document?")) {
                var formdata = new FormData();
                formdata.append("type", "delete_document");
                formdata.append("event_id", eventItem._id);
                formdata.append("team_id", team._id);
                formdata.append('document_url', docfile);

                var secureHeader = new Headers();
                secureHeader.append('Authorization', 'Bearer ' + token);
                secureHeader.append('device_type', 'W');
                const requestOptions = {
                    method: 'POST',
                    redirect: 'follow',
                    body: formdata,
                    headers: secureHeader
                };

                await fetch(process.env.REACT_APP_APIURL + '/app_event', requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        refreshEvent();
                    })
                    .catch(error => console.log('error', error));
            }
        }
        return (
            !loading ?
                <>
                    <div className='flex flex-wrap p-7.5'>
                        {location !== null &&
                        <div className='col-left w-full md:w-1/2 lg:w-auto lg:flex-1 mb-5'>
                            <div className='map-wrapper text-right'>                                
                                <PlaiMap location={location} zoomLevel={15} />                                
                            </div>
                        </div>
                        }
                        <div className={`col-right w-full ${location !== null ? 'md:w-1/2 lg:w-2/3 md:pl-6 xl:pl-[60px]' : ''}`}>
                            <h3>Event Detail</h3>
                            {(eventItem.description !== undefined && eventItem.description !== '') &&
                                <div className='event-description pb-8 mb-8' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(eventItem.description) }} />
                             }
                            {(Object.keys(eventItem.score_details).length!==0) &&
                                <LiveScoreCard score_details={eventItem.score_details} live_stream_link={eventItem.live_stream_link} />
                            }
                            {(eventItem.groups && eventItem.groups.length > 0) &&
                                <div className='article-groups mb-5'>
                                    <h3>Sent to</h3>
                                    <div className='flex flex-wrap items-center'>
                                        {eventItem.groups && eventItem.groups.map((grp, index) => {
                                            return (
                                                <div className="mr-2 mb-4 font-medium text-[14px] leading-[21px] py-[4px] px-[19px] rounded-[14px] bg-[#3139610d]" key={index}>{grp}</div>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            }
                            
                            {(eventItem.coaches !== undefined && eventItem.coaches.length > 0) &&
                                <div className='coaches pt-6'>
                                    <h3>Coaches</h3>
                                    <ul className='flex flex-wrap'>
                                        {eventItem.coaches.map((coach, index) => {
                                            return (
                                                <CoachItem key={index} coach={coach} /> 
                                            )
                                        })}
                                    </ul>
                                </div>
                            }
                            {(eventItem.users && eventItem.users.length > 0) &&
                                <div className='article-groups mb-5'>
                                    <h3>Users</h3>
                                    <div className='flex flex-wrap items-center'>
                                        {eventItem.users && eventItem.users.map((user, index) => {
                                            return (
                                                <div className="mr-2 mb-4 font-medium text-[14px] leading-[21px] py-[4px] px-[19px] rounded-[14px] bg-[#3139610d]" key={index}>{user.name}</div>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            }
                            {(eventItem.user_responses_new && eventItem.user_responses_new.length>0) &&
                                <div className='article-groups mb-5'>
                                    <h3>Responses</h3>
                                    <div className='flex flex-wrap items-center'>
                                        {eventItem.user_responses_new && eventItem.user_responses_new.map((response, index) => {
                                            let cls = '';
                                            if(response.title.toLowerCase().includes('not available')){
                                                cls='bg-[#ff000033] border-[1px] border-[#ff0000] text-[#ff0000]';
                                            }
                                            else if(response.title.toLowerCase().includes('available')){
                                                cls='bg-[#1AB90033] border-[1px] border-[#1AB900] text-[#1AB900]';
                                            }
                                            else if(response.title.toLowerCase().includes('more time')){
                                                cls='bg-[#FF890033] border-[1px] border-[#FF8900] text-[#FF8900]';
                                            }
                                            return (
                                                <div className={`mr-2 mb-4 py-[4px] px-[19px] rounded-[14px] ${cls}`} key={index}>
                                                    <div className='font-medium text-[14px] leading-[21px]'>{response.title}</div>
                                                    {response.note!=='' &&
                                                        <div className='font-medium text-[12px] leading-[16px] text-black'>{response.note}</div>
                                                    }
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            }
                            {(eventItem.user_rejected_responses && eventItem.user_rejected_responses.length > 0) &&
                                <div className='article-groups mb-5'>
                                    <h3>Reason</h3>
                                    <div className='flex flex-wrap items-center'>
                                        {eventItem.user_rejected_responses && eventItem.user_rejected_responses.map((userreason, index) => {
                                            return (
                                                <div className="mr-2 mb-4 font-medium text-[14px] leading-[21px] py-[4px] px-[19px] rounded-[14px] bg-[#3139610d]" key={index}>{userreason}</div>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            }                            
                            {(eventItem.documents !== undefined && eventItem.documents.length > 0) &&
                                <div className='documents'>
                                    <h3>Documents</h3>
                                    <ul className='flex flex-wrap'>
                                        {eventItem.documents.map((doc, index) => {
                                            return (
                                                <li key={index}>
                                                    <div className='mr-5 mb-5 w-[150px] h-[180px] relative'>
                                                        <DocumentView doc={doc} />
                                                        {(eventItem.user_role === 'admin' || eventItem.user_role === 'group_admin') &&
                                                            <button type="button" className='button-tiny delete absolute top-[10px] right-[10px] rounded-full' onClick={() => { deletDocument(doc) }}></button>
                                                        }
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="py-5 text-center"><Spinner /></div>
                </>
        )
    }

    const TabEventResponses = (props) => {
        const [availabilityLoader, setAvailabilityLoader] = useState(false);
        const [acceptedResponses, setAcceptedResponses] = useState([]);
        const [rejectedResponses, setRejectedResponses] = useState([]);
        const [pendingResponses, setpendingResponses] = useState([]);
        const [NeedTimeResponses, setNeedTimeResponses] = useState([]);
        const [WaitingResponses, setWaitingResponses] = useState([]);

        const [acceptedTitle, setAcceptedTitles] = useState(null);
        const [rejectedTitle, setRejectedTitles] = useState(null);
        const [pendingTitle, setpendingTitles] = useState(null);
        const [NeedTimeTitle, setNeedTimeTitles] = useState(null);
        const [WaitingTitle, setWaitingTitles] = useState(null);
        const [CsvExportResponses,setCsvExportResponse] = useState([]);
        
        const [totalReponses, setTotalResponses] = useState(0);
        const [totalAcceptedReponses, setTotalAcceptedResponses] = useState(0);
        const [totalRejectedReponses, setTotalRejectedResponses] = useState(0);
        const [totalNeedTimeResponses, setTotalNeedTimeResponses] = useState(0);
        const [totalPendingReponses, setTotalPendingResponses] = useState(0);
        const [showNotResponded, setShowNotRespondedList] = useState(false);
        const [requestAvailMessage,setRequestAvailMessage] = useState(null);
        const [showSortingOpt, setShowSortingOpt] = useState(false);
        const [filterBy, setFilterBy] = useState('all');

        const sendRequestAvailibities = async() =>{
            setAvailabilityLoader(true);
            var formdata = new FormData();
            formdata.append("type", "ask_for_response");
            formdata.append("team_id", team._id);
            formdata.append("event_id", eventItem._id);
            var secureHeader = new Headers();
            secureHeader.append('Authorization','Bearer ' + token);
            secureHeader.append('device_type','W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_event' , requestOptions)
            .then(rresponse => rresponse.text())
            .then(result => {
                let rdata = JSON.parse(result);
                setAvailabilityLoader(false);
                setRequestAvailMessage(rdata.message);           
            })
            .catch(error => console.log('error', error));
        }

        const showfilterBy = (fby) =>{
            props.responses.forEach((responseList) => {                
                if (responseList.type === 'approved') {
                    let acceptedResponses=[];
                    if(fby==='paid'){
                        acceptedResponses = responseList.item.filter((responseItem)=>(responseItem.is_paid==='PAID'));                        
                    }
                    if(fby==='unpaid'){
                        acceptedResponses = responseList.item.filter((responseItem)=>(responseItem.is_paid==='NOT PAID'));
                    }
                    if(fby==='all'){
                        acceptedResponses = responseList.item;
                    }
                    
                    setAcceptedResponses(acceptedResponses);
                    setAcceptedTitles(responseList.name)
                }
                if (responseList.type === 'rejected') {
                    let rejectedResponses=[];
                    if(fby==='paid'){
                        rejectedResponses = responseList.item.filter((responseItem)=>(responseItem.is_paid==='PAID'));
                    }
                    if(fby==='unpaid'){
                        rejectedResponses = responseList.item.filter((responseItem)=>(responseItem.is_paid==='NOT PAID'));
                    }
                    if(fby==='all'){
                        rejectedResponses = responseList.item;                        
                    }
                    
                    setRejectedResponses(rejectedResponses);                        
                    setRejectedTitles(responseList.name)
                }
                if (responseList.type === 'pending_approval') {
                    let pendingResponses=[];
                    if(fby==='paid'){
                        pendingResponses = responseList.item.filter((responseItem)=>(responseItem.is_paid==='PAID'));
                    }
                    if(fby==='unpaid'){
                        pendingResponses = responseList.item.filter((responseItem)=>(responseItem.is_paid==='NOT PAID'));
                    }
                    if(fby==='all'){
                        pendingResponses = responseList.item;
                    }
                    setpendingResponses(pendingResponses);                        
                    setpendingTitles(responseList.name)
                }
                if (responseList.type === 'need_more_time') {
                    let moretimeResponses=[];
                    if(fby==='paid'){
                        moretimeResponses = responseList.item.filter((responseItem)=>(responseItem.is_paid==='PAID'));
                    }
                    if(fby==='unpaid'){
                        moretimeResponses = responseList.item.filter((responseItem)=>(responseItem.is_paid==='NOT PAID'));
                    }
                    if(fby==='all'){
                        moretimeResponses = responseList.item;
                    }
                    setNeedTimeResponses(moretimeResponses);
                    setNeedTimeTitles(responseList.name);
                }
                if(responseList.type === 'waitlist'){
                    let waitlistResponses=[];
                    if(fby==='paid'){
                        waitlistResponses = responseList.item.filter((responseItem)=>(responseItem.is_paid==='PAID'));
                    }
                    if(fby==='unpaid'){
                        waitlistResponses = responseList.item.filter((responseItem)=>(responseItem.is_paid==='NOT PAID'));
                    }
                    if(fby==='all'){
                        waitlistResponses = responseList.item;
                    }
                    setWaitingResponses(waitlistResponses);
                    setWaitingTitles(responseList.name);
                }
            });

            setShowSortingOpt(false);
            setFilterBy(fby);
        }

        useEffect(() => {
            setTotalAcceptedResponses(props.available_count);
            setTotalRejectedResponses(props.unavailable_count);
            setTotalNeedTimeResponses(props.need_more_time_count);
            setTotalPendingResponses(props.yet_to_respond_count);            
            if (props.responses !== undefined && props.responses.length > 0) {
                let toalResponses = 0;                
                props.responses.forEach((responseList) => {
                    if (responseList.type === 'approved') {
                        setAcceptedResponses(responseList.item);
                        setAcceptedTitles(responseList.name)
                    }
                    if (responseList.type === 'rejected') {
                        setRejectedResponses(responseList.item);                        
                        setRejectedTitles(responseList.name)
                    }
                    if (responseList.type === 'pending_approval') {
                        setpendingResponses(responseList.item);                        
                        setpendingTitles(responseList.name)
                    }
                    if (responseList.type === 'need_more_time') {
                        setNeedTimeResponses(responseList.item);
                        setNeedTimeTitles(responseList.name);
                    }
                    if(responseList.type === 'waitlist'){
                        setWaitingResponses(responseList.item);
                        setWaitingTitles(responseList.name);
                    }
                    toalResponses += responseList.item.length;
                });

                let exportList = [];
                props.responses.forEach((responseListExp) => {
                    responseListExp.item.forEach((responseListExpItem)=>{
                        let tmpExport = {};
                        tmpExport.eventName = eventItem.name;
                        tmpExport.eventDateStart = eventItem.dateStart_display;
                        tmpExport.eventTimeStart = eventItem.timeStart_display;
                        tmpExport.eventLocation = eventItem.location;
                        tmpExport.responseTimeStamp = responseListExpItem.date;
                        tmpExport.respondedBy = responseListExpItem.user_name;
                        tmpExport.availabilityOffered = responseListExp.type;
                        exportList.push(tmpExport);
                    });
                });
                setTotalResponses(toalResponses);
                setCsvExportResponse({
                    data: exportList,
                    headers: [
                        { label: "EVENT NAME", key: "eventName" },
                        { label: "EVENT DATE", key: "eventDateStart" },
                        { label: "EVENT START TIME", key: "eventTimeStart" },
                        { label: "EVENT LOCATION", key: "eventLocation" },
                        { label: "RESPONSE TIMESTAMP", key: "responseTimeStamp" },
                        { label: "WHO RESPONDED", key: "respondedBy" },
                        { label: "AVAILABILITY OFFERED", key: "availabilityOffered" },                        
                    ],
                    filename: 'Responses.csv'
                });
            }
        }, [props.responses]);

        return (
            <>
                <div className='p-5 md:p-7.5'>
                    <div className='w-full'>
                        <div className='flex justify-start flex-wrap'>
                            <div className='mb-5 mr-2.5 xl:mr-5'>
                                <button type="button" className='response-group-btn accepted'>{totalAcceptedReponses}</button>
                            </div>
                            <div className='mb-5 mr-2.5 xl:mr-5'>
                                <button type="button" className='response-group-btn rejected'>{totalRejectedReponses}</button>
                            </div>
                            <div className='mb-5 mr-2.5 xl:mr-5'>
                                <button type="button" className='response-group-btn pending'>{totalNeedTimeResponses}</button>
                            </div>
                            <div className='mr-2.5 xl:mr-5'>
                                <button type="button" className='response-group-btn all' onClick={()=>{setShowNotRespondedList(true);}}>{totalPendingReponses}</button>
                            </div>
                            {totalReponses > 0 &&
                                <div className='mr-2.5 xl:mr-5 mb-5'>
                                    {<CSVLink 
                                        className="button button-action button-export flex items-center"                                    
                                        {...CsvExportResponses}
                                    >Export                               
                                    </CSVLink>}
                                </div>
                            }                             
                            {!availabilityLoader ?
                                <div className='mb-5'>
                                    <button type="button" className='button button-action' onClick={()=>{ sendRequestAvailibities(); }}>Request Availabilities</button>
                                    {requestAvailMessage!==null &&
                                        <div className="popup-form">
                                        <div className="popup-form-wrap relative !w-full !max-w-[300px]">
                                            <p className="text-center mb-5">{requestAvailMessage}</p>
                                            <div className='flex justify-center'>
                                                <div><button className='button' type="button" onClick={(e)=>{setRequestAvailMessage(null)}}>Close</button></div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>
                            :
                                <div className='mb-5'><img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={12} height={12} alt="" /></div>
                            }
                            {props.isAddedProduct &&
                                <div className='mb-5 relative ml-5'>
                                    <button type="button" className={`button-icon button-icon-sort`} onClick={(e)=>{setShowSortingOpt(true);}}><span>Filter</span></button>
                                    <ul className={`sort-actions absolute overflow-hidden rounded-lg bg-white top-full right-0 ${!showSortingOpt ? 'hidden' : ''}`}>
                                        <li className={filterBy==='all' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{showfilterBy('all');}}>All</div></li>
                                        <li className={filterBy==='paid' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{showfilterBy('paid');}}>Paid</div></li>
                                        <li className={filterBy==='unpaid' ? 'active' : ''}><div className='cursor-pointer' onClick={()=>{showfilterBy('unpaid');}}>Unpaid</div></li>                        
                                    </ul>                                
                                </div>
                            }
                        </div>
                        {showNotResponded &&
                            <NoRespondedList setShowForm={setShowNotRespondedList} />
                        }                        
                        {pendingResponses.length > 0 ?
                            <div className='responses pt-6'>
                                <div className='responses-label pending flex flex-row'>{pendingTitle}</div>
                                <ul>
                                    {pendingResponses.map((response, index) => {
                                        return (
                                            <ResponseRow response={response} status="pending_approval" teamid={team._id} eventid={eventItem._id} eventType={eventItem.type} key={index} setEventItem={setEventItem} refreshEvent={refreshEvent} eventUpdating={loading} setActionLoading={setActionLoading}/>
                                        )
                                    })}
                                </ul>
                            </div>
                            : null}
                        {acceptedResponses.length > 0 ?
                            <div className='responses pt-6'>
                                <div className='responses-label accepted flex flex-row'>{acceptedTitle}</div>
                                <ul>
                                    {acceptedResponses.map((response, index) => {
                                        return (
                                            <ResponseRow response={response} status="approved" teamid={team._id} eventid={eventItem._id} eventType={eventItem.type} key={index} setEventItem={setEventItem} refreshEvent={refreshEvent} eventUpdating={loading} setActionLoading={setActionLoading}/>
                                        )
                                    })}
                                </ul>
                            </div>
                            : null}
                        {rejectedResponses.length > 0 ?
                            <div className='responses pt-6'>
                                <div className='responses-label rejected flex flex-row'>{rejectedTitle}</div>
                                <ul>
                                    {rejectedResponses.map((response, index) => {
                                        return (
                                            <ResponseRow response={response} status="rejected" teamid={team._id} eventid={eventItem._id} eventType={eventItem.type} key={index} setEventItem={setEventItem} refreshEvent={refreshEvent} eventUpdating={loading} setActionLoading={setActionLoading}/>
                                        )
                                    })}
                                </ul>
                            </div>
                            : null}
                        {NeedTimeResponses.length > 0 ?
                            <div className='responses pt-6'>
                                <div className='responses-label need-time flex flex-row'>{NeedTimeTitle}</div>
                                <ul>
                                    {NeedTimeResponses.map((response, index) => {
                                        return (
                                            <ResponseRow response={response} status="need_more_time" teamid={team._id} eventid={eventItem._id} eventType={eventItem.type} key={index} setEventItem={setEventItem} refreshEvent={refreshEvent} eventUpdating={loading} setActionLoading={setActionLoading}/>
                                        )
                                    })}
                                </ul>
                            </div>
                            : null}
                        {WaitingResponses.length > 0 ?
                            <div className='responses pt-6'>
                                <div className='responses-label pending flex flex-row'>{WaitingTitle}</div>
                                <ul>
                                    {WaitingResponses.map((response, index) => {
                                        return (
                                            <ResponseRow response={response} status="waitlist" teamid={team._id} eventid={eventItem._id} eventType={eventItem.type} key={index} setEventItem={setEventItem} refreshEvent={refreshEvent} eventUpdating={loading} setActionLoading={setActionLoading}/>
                                        )
                                    })}
                                </ul>
                            </div>
                            : null}
                        {(pendingResponses.length <= 0 &&
                            NeedTimeResponses.length <= 0 &&
                            rejectedResponses.length <= 0 &&
                            acceptedResponses.length <= 0) &&
                            <div className="empty text-center">
                                <div className="image"><img src={revents} alt="" width={228} height={202} /></div>
                                <div className="title mb-5">No Event Yet?</div>
                                <div className="description mb-8">
                                    Remember the day when you had to track your player responses on WhatsApp? I know, right...
                                </div>
                            </div>
                        }
                    </div>                    
                </div>
            </>
        )
    }

    const downloadPDf=async()=>{
        setDownloadLoading(true);
        var formdata = new FormData();
            formdata.append("type", "get_event_pdf");
            formdata.append("event_id", eventItem._id);
            formdata.append("team_id", team._id);

            var secureHeader = new Headers();
            secureHeader.append('Authorization', 'Bearer ' + token);
            secureHeader.append('device_type', 'W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_event', requestOptions)
                .then(response => response.text())
                .then(result => {
                    const res=JSON.parse(result);
                    if(res.status===1 && res.data.pdf!==undefined && res.data.pdf!==""){
                        new JsFileDownloader({ 
                            url: res.data.pdf,
                        })
                        .then(function () {
                            setDownloadLoading(false);
                        })
                        .catch(function (error) {
                            setDownloadLoading(false);
                            alert(error);
                        });
                    }
                    else{
                        setDownloadLoading(false);
                        alert("PDF not found");
                    }
                })
                .catch(error => console.log('error', error));
    }
    
    const TabEvdntAttendance = (props) => {
        const [isAttendiManually, setAttendiManually] = useState(false);
        const [CsvExportAttendense,setCsvExportAttendense] = useState([]);
        const [isExportBuild, setIsExportBuild] = useState(false);

        useEffect(()=>{
            let exportList = [];
            if(props.attendance !== undefined && props.attendance.length > 0){                
                props.attendance.forEach((attendee) => {                
                    let tmpExport = {};
                    tmpExport.eventName = eventItem.name;
                    tmpExport.eventDateStart = eventItem.dateStart_display;
                    tmpExport.eventTimeStart = eventItem.timeStart_display;
                    tmpExport.eventLocation = eventItem.location;
                    tmpExport.attendeeName = attendee.name;
                    tmpExport.responded =!attendee.is_manual_attendance ? "Responded on PLAI" : "Manually Added";
                    tmpExport.status = attendee.attended ? 'Attended' : 'Did not attend';
                    exportList.push(tmpExport);
                });

                setCsvExportAttendense({
                    data: exportList,
                    headers: [
                        { label: "EVENT NAME", key: "eventName" },
                        { label: "EVENT DATE", key: "eventDateStart" },
                        { label: "EVENT START TIME", key: "eventTimeStart" },
                        { label: "EVENT LOCATION", key: "eventLocation" },
                        { label: "WHO IS ATTENDEE", key: "attendeeName" },
                        { label: "RESPONSE", key: "responded" },
                        { label: "STATUS", key: "status" },                    
                    ],
                    filename: 'Attendance.csv'
                });
                setIsExportBuild(true);
            }
        },[props.attendance]);
        return (
            <>
                <div className='p-5 md:p-7.5'>
                    <div className='w-full'>
                        
                        <div className='flex justify-start flex-wrap gap-2.5 mb-5'>
                            {isExportBuild &&
                                <div>
                                    {<CSVLink 
                                        className="button button-action button-export flex items-center"                                    
                                        {...CsvExportAttendense}
                                    >Export                               
                                    </CSVLink>}
                                </div>
                            }
                            {props.attendance !== undefined && props.attendance.length > 0 &&
                                <>
                                {!downloadLoading ?
                                    <button type="button" onClick={()=>{downloadPDf()}} className='bg-[var(--color-yellow)] rounded-full w-7.5 h-7.5 flex items-center justify-center hover:bg-white'>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM13 7L11.59 5.59L9 8.17V0H7V8.17L4.41 5.59L3 7L8 12L13 7Z" fill="black" />
                                        </svg>
                                    </button>
                                    :
                                    <img src="data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20px' height='20px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E" width={30} height={30} alt="" />
                                }
                                </>
                            }
                        </div>                        
                        {props.attendance !== undefined && props.attendance.length > 0 ?
                            <>
                            <ul className='attendence-rows mb-5'>
                                {props.attendance.map((attendi, index) => {
                                    return (
                                        <li key={index}>
                                            <AttendeeRow attendi={attendi} team={team} eventid={eventItem._id} key={index} refreshEvent={refreshEvent} eventUpdating={loading} userrole={eventItem.user_role} />
                                        </li>
                                    )
                                })}
                            </ul>
                            {(eventItem.user_role === 'admin' || eventItem.user_role === 'group_admin') &&
                                <>
                                <div className='flex justify-center'>
                                    <div>
                                        <button className='button' onClick={()=>{setAttendiManually(true)}}>Add member manually</button>
                                    </div>
                                </div>
                                {isAttendiManually &&
                                    <ManualAttendi setShowForm={setAttendiManually} />
                                }
                                </>
                            }
                            </>
                            :
                            <>
                                <div className="empty text-center">
                                    <div className="image"><img src={revents} alt="" width={228} height={202} /></div>
                                    <div className="title mb-5">No Event Yet?</div>
                                    <div className="description mb-8">
                                        APPROVED participants from your "Responses" tab will appear here for attendance tracking!
                                    </div>
                                    {(eventItem.user_role === 'admin' || eventItem.user_role === 'group_admin') &&
                                        <>
                                        <div className='flex justify-center'>
                                            <div>
                                                <button className='button' onClick={()=>{setAttendiManually(true)}}>Add member manually</button>
                                            </div>
                                        </div>
                                        {isAttendiManually &&
                                            <ManualAttendi setShowForm={setAttendiManually} />
                                        }
                                        </>
                                    }
                                </div>
                            </>
                        }
                    </div>                    
                </div>
            </>
        )
    }

    const ResponseNewForm = ({ setShowForm }) => {
        return (
            <div className="popup-form">
                <div className="popup-form-wrap relative">
                    <ResponseForm team={team} eventitem={eventItem} refresh={refreshEvent} setShowForm={setShowForm} />
                </div>
            </div>
        )
    }

    const AddToCalendarEvent = ({ eventItem, setShowForm }) => {
        const name = eventItem.name + ' - ' + team.name;
        const title = eventItem.name + ' - ' + team.name;
        const details = eventItem.description;
        const venue = eventItem.location;
        const startDate = dayjs.utc(eventItem.dateStart_display + eventItem.timeStart_display).format('YYYYMMDDTHHmmssZ');
        const endDate = dayjs.utc(eventItem.dateEnd_display + eventItem.timeEnd_display).format('YYYYMMDDTHHmmssZ');
        const startDateOutlook = dayjs.utc(eventItem.dateStart_display + eventItem.timeStart_display).format('YYYY-MM-DDTHH:mm:ss');
        const endDateOutlook = dayjs.utc(eventItem.dateEnd_display + eventItem.timeEnd_display).format('YYYY-MM-DDTHH:mm:ss');
        const getRandomKey = () => {
            let n = Math.floor(Math.random() * 999999999999).toString();
            return new Date().getTime().toString() + "_" + n;
        }
        return (
            <>
                <div className="w-[200px] z-[1000] absolute left-0 top-[40px] text-left bg-white rounded-lg p-2.5 text-[#363F6C] border-solid border border-slate-300">
                    <ul>
                        <li className="mb-1.5">
                            <a onClick={() => { setAddToCalendar(false) }} href={`data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0AURL:http://carlsednaoui.github.io/add-to-calendar-buttons/generator/generator.html%0ADTSTART:${startDate}%0ADTEND:${endDate}%0ASUMMARY:${title}%0ADESCRIPTION:${details}%0ALOCATION:${venue}%0AEND:VEVENT%0AEND:VCALENDAR`}>Apple Calendar</a>
                        </li>
                        <li className="mb-1.5">
                            <a onClick={() => { setAddToCalendar(false) }} target="_blank" rel="noreferrer" href={`https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${startDate}/${endDate}&details=${details}&location=${venue}&amp;sprop=&amp;sprop=name:`}>Google Calendar</a>
                        </li>
                        <li className="mb-1.5">
                            <a onClick={() => { setAddToCalendar(false) }} target="_blank" rel="noreferrer" href={`http://calendar.yahoo.com/?v=60&view=m&type=20&title=${title}&st=${startDate}&et=${endDate}&desc=${details}&in_loc=${venue}`}>Yahoo! Calendar</a>
                        </li>
                        <li className="mb-1.5">
                            <a onClick={() => { setAddToCalendar(false) }} target="_blank" rel="noreferrer" href={`https://outlook.live.com/owa/?rru=addevent&subject=${title}&startdt=${startDateOutlook}&enddt=${endDateOutlook}&body=${details}&location=${venue}&allday=false&path=/calendar/view/Month&uid=${getRandomKey}`}>Outlook Web App</a>
                        </li>
                        <li>
                            <a onClick={() => { setAddToCalendar(false) }} href={`data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0AURL:http://carlsednaoui.github.io/add-to-calendar-buttons/generator/generator.html%0ADTSTART:${startDateOutlook}%0ADTEND:${endDateOutlook}%0ASUMMARY:${title}%0ADESCRIPTION:${details}%0ALOCATION:${venue}%0AEND:VEVENT%0AEND:VCALENDAR`}>Outlook Calendar</a>
                        </li>
                    </ul>
                </div>
            </>
        )
    }

    const deleteEvent = async () => {
        if (window.confirm("Are you sure you want to delete this event?")) {
            var formdata = new FormData();
            formdata.append("type", "delete_event");
            formdata.append("event_id", eventItem._id);
            formdata.append("team_id", team._id);

            var secureHeader = new Headers();
            secureHeader.append('Authorization', 'Bearer ' + token);
            secureHeader.append('device_type', 'W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_event', requestOptions)
                .then(response => response.text())
                .then(result => {
                    window.location.href = '/team/' + team._id + '/events';
                })
                .catch(error => console.log('error', error));
        }
    }

    const cancelEvent = async (isCancelled) => {
        let confirmMsg = 'Are you sure you want to cancel this event?'
        if(isCancelled){
            confirmMsg = 'Are you sure you want to uncancel this event?'
        }

        if (window.confirm(confirmMsg)) {
            var formdata = new FormData();
            formdata.append("type", "cancel_uncancel_event");
            formdata.append("event_id", eventItem._id);
            formdata.append("team_id", team._id);

            var secureHeader = new Headers();
            secureHeader.append('Authorization', 'Bearer ' + token);
            secureHeader.append('device_type', 'W');
            const requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: formdata,
                headers: secureHeader
            };

            await fetch(process.env.REACT_APP_APIURL + '/app_event', requestOptions)
                .then(response => response.text())
                .then(result => {
                    refreshEvent();
                })
                .catch(error => console.log('error', error));
        }
    }

    const collectFees = async() => {
        var formdata = new FormData();
        formdata.append("type", "send_payment_link_for_event");
        formdata.append("event_id", eventItem._id);
        formdata.append("team_id", team._id);

        var secureHeader = new Headers();
        secureHeader.append('Authorization', 'Bearer ' + token);
        secureHeader.append('device_type', 'W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_shop', requestOptions)
            .then(response => response.text())
            .then(result => {
                refreshEvent();
            })
            .catch(error => console.log('error', error));
    }

    const LiveStreamForm=({setShowForm})=>{
        return (
            <div className="popup-form">
                <div className="popup-form-wrap relative !bg-[#363F6C] !w-full !max-w-[550px]">
                    <LiveStreamFormPopup teamID={team._id} eventID={eventItem._id} refresh={refreshEvent} setShowForm={setShowForm} token={token} />
                </div>
            </div>
        )
    }

    const respondButtonstyle = {
        color: "#" + eventItem.text_color,
        backgroundColor: "#" + eventItem.color,
    };

    useEffect(() => {
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if ((showMoreOpt || showAddToCalendar) && ref.current && !ref.current.contains(e.target)) {
                setAddToCalendar(false);
                setShowMoreOpt(false);
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [showAddToCalendar, showMoreOpt]);
    return (
        (!loading && Object.keys(eventItem).length > 0) ?
            <>
                <div className='breadcrumb flex item-center pt-1.5 pb-1 pl-5 pr-7 w-full'>
                    <NavLink to="/" className='home'>
                        <span>Dashboard</span>
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    <NavLink to={`/team/${team._id}/events`}>
                        Event
                    </NavLink>
                    <span className='separator'>&lt;</span>
                    {eventItem.name}
                </div>
                <div className='page-header event-detail flex flex-col justify-center px-5 lg:px-8 py-6 md:py-[52px] relative'>
                    <div className='flex flex-col lg:flex-row w-full'>
                        {eventItem.live_stream_link!=="" ?
                        <>
                            <a href={eventItem.live_stream_link} target="_blank" rel="noreferrer" className='block relative w-[168px] h-[115px]'>
                                <img src={WatchLiveImg} alt="" className='w-full rounded-[13px] w-full h-full absolute object-cover object-center' />
                            </a>
                        </>
                        :
                        <>
                        {eventItem.image && eventItem.image !== "" &&
                            <div className='w-[168px] h-[115px]'>
                                <img src={eventItem.image} alt="" className='w-full rounded-[13px]' />
                            </div>
                        }
                        </>
                        }
                        <div className='flex-1 flex flex-col lg:ml-2.5'>
                            <div className='flex flex-col lg:flex-row justify-between w-full'>
                                <h1 className='flex-1'>{eventItem.name}</h1>
                                <div className='event-actions col-right text-center align-center flex lg:text-right lg:self-end' ref={ref}>
                                    <div className='mr-2.5 mb-2.5 lg:ml-0 lg:mb-0 relative'>
                                        <button type="button" className='button' onClick={() => { setAddToCalendar(!showAddToCalendar); setShowMoreOpt(false); }}>Add to calendar</button>
                                        {showAddToCalendar &&
                                            <AddToCalendarEvent setShowForm={setAddToCalendar} eventItem={eventItem} />
                                        }
                                    </div>
                                    
                                    {eventItem.is_added_product &&
                                        <div className='mr-2.5 mb-2.5 lg:ml-0 lg:mb-0 inline-block relative'>
                                            <button type="button" className='button' onClick={() => { collectFees() }}>Collect Fees</button>
                                        </div>
                                    }
                                    {eventItem.is_exist_in_groups &&
                                        <div className='mr-2.5 mb-2.5 lg:ml-0 lg:mb-0 inline-block relative'>
                                            {!eventItem.is_cancelled ?
                                            <button type="button" className='button' onClick={() => { setShowResponseForm(true) }} style={respondButtonstyle}>{eventItem.status}</button>
                                            :
                                            <button type="button" className='button' style={respondButtonstyle}>{eventItem.status}</button>
                                            }                                            
                                        </div>
                                    }
                                    {eventItem.event_match_id!=="" &&
                                        <div className='mr-2.5 mb-2.5 lg:ml-0 lg:mb-0 inline-block relative'>
                                            <NavLink to={`/team/${team._id}/event/${eventItem._id}/scorecard`} className='button inline-block'>Scorecard</NavLink>
                                        </div>
                                    }
                                    {(team.role==='admin' || (team.role==='member' && team.admin_groups_new !== undefined && team.admin_groups_new.length>0)) &&
                                        <>
                                        <div className='relative'>
                                            <button type="button" className="more-cta rounded-full bg-[#FBDB09] w-[39px] h-[39px] flex items-center justify-center mr-4 hover:bg-white" onClick={(e)=>{setAddToCalendar(false); setShowMoreOpt(!showMoreOpt);}}>
                                                <svg width="6" height="21" viewBox="0 0 6 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.16615 5.19413C4.46227 5.19413 5.51297 4.14342 5.51297 2.84731C5.51297 1.55119 4.46227 0.500488 3.16615 0.500488C1.87004 0.500488 0.819336 1.55119 0.819336 2.84731C0.819336 4.14342 1.87004 5.19413 3.16615 5.19413Z" fill="#363F6C"/>
                                                    <path d="M3.16615 12.8472C4.46227 12.8472 5.51297 11.7965 5.51297 10.5004C5.51297 9.20427 4.46227 8.15356 3.16615 8.15356C1.87004 8.15356 0.819336 9.20427 0.819336 10.5004C0.819336 11.7965 1.87004 12.8472 3.16615 12.8472Z" fill="#363F6C"/>
                                                    <path d="M3.16615 20.5005C4.46227 20.5005 5.51297 19.4498 5.51297 18.1537C5.51297 16.8576 4.46227 15.8069 3.16615 15.8069C1.87004 15.8069 0.819336 16.8576 0.819336 18.1537C0.819336 19.4498 1.87004 20.5005 3.16615 20.5005Z" fill="#363F6C"/>
                                                </svg>
                                            </button>
                                            <ul className={`sort-actions w-[175px] text-[14px] leading-[18px] text-black absolute overflow-hidden rounded-lg bg-white top-full right-0 text-left ${!showMoreOpt ? 'hidden' : ''}`}>                                            
                                                <li><div className='cursor-pointer py-1.5' onClick={(e) => {
                                                    setShowMoreOpt(false); 
                                                    navigate('/team/'+team._id+'/events/duplicate-event',{
                                                        state:{
                                                        event:eventItem,
                                                        }          
                                                    })
                                                }}>Duplicate</div></li>
                                                <li><NavLink to={`/team/${team._id}/event/${eventItem._id}/edit`} className='cursor-pointer py-1.5' onClick={(e) => {navigate()}}>Edit</NavLink></li>
                                                <li><div className='cursor-pointer py-1.5' onClick={(e) => {setOpenSeenBy(true); setShowMoreOpt(false);}}>Seen By</div></li>
                                                <li><div className='cursor-pointer py-1.5' onClick={(e) => {setLiveStreamLinkForm(true); setShowMoreOpt(false);}}>Add Live Stream Link</div></li>
                                                <li><NavLink to={`/team/${team._id}/event/${eventItem._id}/update_score`} className='cursor-pointer py-1.5'>Update Score</NavLink></li>                                                
                                                {eventItem.is_cancelled ?
                                                    <li><div className='cursor-pointer py-1.5' onClick={(e) => { setShowMoreOpt(false); cancelEvent(eventItem.is_cancelled);}}>Uncancel</div></li>
                                                :
                                                    <li><div className='cursor-pointer py-1.5' onClick={(e) => { setShowMoreOpt(false); cancelEvent(eventItem.is_cancelled);}}>Cancel</div></li>
                                                }
                                                <li><div className='cursor-pointer py-1.5' onClick={(e) => { setShowMoreOpt(false); deleteEvent()}}>Delete</div></li>
                                            </ul>
                                        </div>                                            
                                        </>
                                    }
                                </div>
                            </div>
                            <p className='event-type mb-2.5 capitalize'>{eventItem.type}</p>
                            <div className='col-left flex-1 mb-5 xl:mb-0'>
                                <div className='event-metadata flex flex-col lg:flex-row lg:flex-wrap'>
                                    <div className='event-date relative pl-7 lg:mr-5 2xl:mr-8'>{eventItem.dateStart_display ? dayjs.utc(eventItem.dateStart_display).format("ddd DD MMM YYYY") +' To '+dayjs.utc(eventItem.dateEnd_display).format("ddd DD MMM YYYY") : null}</div>
                                    <div className='event-time relative pl-7 lg:mr-5 2xl:mr-8'>{dayjs('1/1/1 '+eventItem.timeStart_display).format('hh:mm A')} to {dayjs('1/1/1 '+eventItem.timeEnd_display).format('hh:mm A')}</div>
                                    {eventItem.location !== '' ?
                                        <div className='event-location relative flex-1 pl-7 pr-2.5 lg:flex-1'>{eventItem.location}</div>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>                
                {openResponseForm &&
                    <ResponseNewForm setShowForm={setShowResponseForm} />
                }
                {openSeenBy && 
                    <EventSeenBy setShowForm={setOpenSeenBy} eventid={eventItem._id} team={team} />
                }
                {liveStreamLinkform &&
                    <LiveStreamForm setShowForm={setLiveStreamLinkForm} />
                }
                <div className="body px-5 lg:px-8 py-10 relative">
                    {actionLoading &&
                        <div className="flex justify-center items-center py-5 text-center absolute w-full h-full top-0 left-0 bg-black/40 z-10"><Spinner /></div>
                    }
                    <div className="event-contents rounded-xl bg-white">
                        <TabsMenu
                            tabActive={tabActive}
                            setTabActive={setTabActive}
                            ismobile={ismobile}
                            responses={eventItem.requests_new}
                            attendance={eventItem.attendance}
                        />
                        <div className="tabs-content">
                            {tabActive === 'overview' && <TabEventOverview />}
                            {tabActive === 'responses' && <TabEventResponses responses={eventItem.requests_new} need_more_time_count={eventItem.need_more_time_count} available_count= {eventItem.available_count} unavailable_count={eventItem.unavailable_count} yet_to_respond_count={eventItem.yet_to_respond_count} isAddedProduct = {eventItem.is_added_product}/>}
                            {tabActive === 'attendance' && <TabEvdntAttendance attendance={eventItem.attendance} />}
                        </div>
                    </div>
                    {tabActive === 'overview' &&
                        <>
                        {eventItem.is_exist_in_groups &&
                            <div className='pt-10'>
                                <Comments element={eventItem} team={team} type="event" commentList={eventItem.comments ? eventItem.comments : []} commentsEnabled={eventItem.commentsEnabled} refreshData={refreshEvent} />
                            </div>
                        }
                        </>
                    }
                </div>
            </>
            :
            <>
                <div className="py-5 text-center"><Spinner /></div>
            </>
    )
}

const EventDetail = () => {
    const { id, eventid } = useParams();
    return (
        <>
            <Sidebar type="thin" />
            <TeamContextProvider id={id}>
                <div id="page" className="page-eventdetail">
                    <AdminNavbar heading="Events" />
                    <EventView id={eventid} />
                </div>
            </TeamContextProvider>
        </>
    );
}

export default EventDetail;