import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import placeholderImg from '../../../assets/images/placeholder.png';
import copyIcon from "../../../assets/images/copy.svg";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import MemberDetail from './MemberDetail';
import DeleteMemberWithNewCreator from './DeleteMemberWithNewCreator';
import TeamMembershipCards from './TeamMembershipCards/TeamMembershipCards';

const MembersRow = ({teamMember, isMobile, team, refreshMembers, isInvitedMember, setIsCheckMembers, isCheckMembers, setIsCheckDelInvites, isCheckDelInvites, showExportOptions}) =>{
    const [openDetail, setOpenDetail] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [memberParents, setMemberParents] = useState([]);
    const [memberChilds, setMemberChilds] = useState([]);
    const [showMoreOpt, setShowMoreOpt] = useState(false);
    const [showMemberShipCard,setShowMembershipCard] = useState(false);
    const navigate = useNavigate();
    const ref = useRef();

    let token = localStorage.getItem('auth_token') && localStorage.getItem('auth_token')!==undefined
        ? localStorage.getItem('auth_token')
        : '';

    const deleteNormalMemberAction = async() =>{
        var formdata = new FormData();
        formdata.append("type", "delete_member");
        formdata.append("member_id",teamMember._id);
        formdata.append("team_id", team._id);
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_members' , requestOptions)
        .then(response => response.text())
        .then(result => {
          setOpenDetail(false);
          refreshMembers();
        })
        .catch(error => console.log('error', error));
    }

    const deleteCreatorMemberAction = () =>{
      confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <DeleteMemberWithNewCreator teamMember={teamMember} teamID={team._id} onClose={onClose} token={token} setOpenDetail={setOpenDetail} refreshMembers={refreshMembers} />
            );
        }
    });
    }

    const deleteMember = async ()=>{
      if(teamMember.is_club_creator){
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                <div className='confirm-ui'>
                    <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
                    <p className="text-center text-[14px] leading-[18px] mb-5">This user is Team Creator, in deleting them from the team you will need to transfer ownership to a new member. Are you sure you want to delete this member from your team?</p>
                    <div className="flex justify-center">
                        <button className="button button-blue mx-1" onClick={onClose}>No</button>
                        <button className="button mx-1"
                        onClick={async() => {
                            onClose(); 
                            deleteCreatorMemberAction();                            
                        }}
                        >
                        Yes
                        </button>
                    </div>
                </div>
                );
            }
        });
      }
      else{
          confirmAlert({
              customUI: ({ onClose }) => {
                  return (
                  <div className='confirm-ui'>
                      <div className="font-semibold uppercase text-[22px] leading-[30px] text-center mb-5">PLAI</div>
                      <p className="text-center text-[14px] leading-[18px] mb-5">Are you sure you want to delete this member?</p>
                      <div className="flex justify-center">
                          <button className="button button-blue mx-1" onClick={onClose}>No</button>
                          <button className="button mx-1"
                          onClick={() => { 
                              deleteNormalMemberAction();                       
                              onClose();
                          }}
                          >
                          Yes
                          </button>
                      </div>
                  </div>
                  );
              }
          });
      }      
    }

    const deleteInviteMember = async ()=>{
      if(window.confirm("Are you sure you want to delete this invited member?")){
        var formdata = new FormData();
        formdata.append("type", "delete_invite");
        formdata.append("invite_id",teamMember._id);
        formdata.append("team_id", team._id);
        
        var secureHeader = new Headers();
        secureHeader.append('Authorization','Bearer ' + token);
        secureHeader.append('device_type','W');
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: formdata,
            headers: secureHeader
        };

        await fetch(process.env.REACT_APP_APIURL + '/app_members' , requestOptions)
        .then(response => response.text())
        .then(result => {
          setOpenDetail(false);
          refreshMembers();
        })
        .catch(error => console.log('error', error));
      }
    }

    const handleClickMembers = e => {
        const { id, checked, value } = e.target;
        let tmpObj = {};
        Object.assign(tmpObj, JSON.parse(value));        
        setIsCheckMembers([...isCheckMembers, tmpObj]);
        if (!checked) {
          setIsCheckMembers(isCheckMembers.filter((item) => {return !item._id.includes(id)}));
        }
    }

    const handleClickDelMembers = e => {
      const { id, checked, value } = e.target;
      //let tmpObj = {};      
      setIsCheckDelInvites([...isCheckDelInvites, value]);
      if (!checked) {
        setIsCheckDelInvites(isCheckDelInvites.filter((item) => {return !item.includes(id)}));
      }
    }

    const copyToClipboard=(data) => {
      let textField = document.createElement('textarea');
      textField.innerText = data;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
      alert('Copied!');
    }

    useEffect(()=>{
      if(teamMember.accountType==='children'){
        if(teamMember.parents.length > 0){
          var tmpParents = [];
          teamMember.parents.forEach(pItem=>{
            tmpParents.push(pItem.name);
          });
          setMemberParents(tmpParents.join(','));
        }
      }
      else{
        if(teamMember.childrens!==undefined && teamMember.childrens.length > 0){
          var tmpchild = [];
          teamMember.childrens.forEach(cItem=>{
            tmpchild.push(cItem.name);
          });
          setMemberChilds(tmpchild.join(','));
        }
      }
    },[teamMember]);

    useEffect(() => {
      const checkIfClickedOutside = e => {
          // If the menu is open and the clicked target is not within the menu,
          // then close the menu
          if ((showMoreOpt) && ref.current && !ref.current.contains(e.target)) {
              setShowMoreOpt(false);
          }
      }
      document.addEventListener("mousedown", checkIfClickedOutside);
      return () => {
          document.removeEventListener("mousedown", checkIfClickedOutside)
      }
    }, [showMoreOpt]);
    
    return(
      !isInvitedMember ?
      <>
        {!isMobile ?
        <tr className='border-b border-black-100/10'>
          <td className='py-3.5 pl-2.5 md:pl-[30px] align-top w-[120px]'>
            {showExportOptions ?
              <div className='flex items-center'>
                <label key={teamMember._id} className='text-[12px] mr-3 text-black font-light cursor-pointer'>
                    <input                    
                        type="checkbox"
                        name={teamMember._id}
                        id={teamMember._id}
                        value={JSON.stringify(teamMember)}
                        className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-white mt-1 align-top float-left mr-2 cursor-pointer'
                        onChange={handleClickMembers}
                        checked={isCheckMembers.some((item)=>{ return item._id===teamMember._id })}
                    />
                </label>
                {teamMember.profilePicture !== '' ?  
                  <img src={teamMember.profilePicture} alt="" width={50} height={50} className="rounded-full cursor-pointer" onClick={(e)=>{if(teamMember.can_see_member_profile) setOpenDetail(true)}}/>
                  :
                  <img src={placeholderImg} alt="" width={50} height={50} className="rounded-full cursor-pointer" onClick={(e)=>{if(teamMember.can_see_member_profile) setOpenDetail(true)}}/>
                }
              </div>
              :    
              <>
                {teamMember.profilePicture !== '' ?  
                  <img src={teamMember.profilePicture} alt="" width={50} height={50} className="rounded-full cursor-pointer" onClick={(e)=>{if(teamMember.can_see_member_profile) setOpenDetail(true)}}/>
                  :
                  <img src={placeholderImg} alt="" width={50} height={50} className="rounded-full cursor-pointer" onClick={(e)=>{if(teamMember.can_see_member_profile) setOpenDetail(true)}}/>
                }
              </>
            }            
            {openDetail &&
              <MemberDetail setShowForm={setOpenDetail} team={team} teamMemberID={teamMember._id} memberSub={teamMember.sub} refreshMembers={refreshMembers} isEdit={isEdit} setIsEdit={setIsEdit} deleteMember={deleteMember} />
            }
          </td>
          <td className='py-3.5 w-1/3 md:w-auto'>          
              <div className='member-name uppercase cursor-pointer' onClick={(e)=>{if(teamMember.can_see_member_profile) setOpenDetail(true)}}>{teamMember.name}</div>
              <div className={`member-role uppercase is-${teamMember.user_role}`}>
                {teamMember.user_role==='group_admin' ? 'Group admin' : teamMember.user_role}                                
              </div>
              {teamMember.accountType==='children' ?
                <>
                  {teamMember.parents.length>0 &&
                    <>
                    <div className='text-[0.75rem]'>
                      {teamMember.parents.length===1 ? 'Parent: ': 'Parents: '}
                      {memberParents}
                    </div>
                    </>
                  }
                </>
                :
                <>
                  {teamMember.childrens!==undefined && teamMember.childrens.length>0 &&
                    <>
                    <div className='text-[0.75rem]'>
                      {teamMember.childrens.length===1 ? 'Child: ': 'Childs: '}
                      {memberChilds}
                    </div>
                    </>
                  }
                </>
              }
              {(teamMember.can_see_member_profile) &&
              <>
                <div className='md:hidden'>
                  {(teamMember.dateOfBirth!=='' && teamMember.dateOfBirth!==null) ? dayjs(teamMember.dateOfBirth).format('DD / MMM / YYYY'): (teamMember.is_above_18 ? 'Over 18 years' : 'Under 18 years')}
                </div>
                <div className='flex items-center lg:hidden'>
                  {teamMember.email.trim()!=='' &&
                    <>
                    <img src={copyIcon} alt="" className='mr-1.5' onClick={()=>copyToClipboard(teamMember.email)}/><a href={`mailto:${teamMember.email}`} className="truncate  text-ellipsis overflow-hidden">{teamMember.email}</a>
                    </>
                  }
                </div>
                <div className='flex items-center lg:hidden'>
                  {teamMember.phoneNumber &&
                  <>
                    <img src={copyIcon} alt="" className='mr-1.5' onClick={()=>copyToClipboard(teamMember.phoneCode!=="" ? teamMember.phoneCode+' '+teamMember.phoneNumber : teamMember.phoneNumber)}/>{teamMember.phoneCode!=="" ? teamMember.phoneCode+' '+teamMember.phoneNumber : teamMember.phoneNumber}
                  </>
                  }
                </div>
              </>
              }
          </td>
          {(teamMember.can_see_member_profile) &&
          <>
            <td className='py-3.5 hidden lg:table-cell'>
              {teamMember.email.trim()!=="" &&
                <>
                <div className='flex items-center'><img src={copyIcon} alt="" className='mr-1.5' onClick={()=>copyToClipboard(teamMember.email)}/><a href={`mailto:${teamMember.email}`}>{teamMember.email}</a></div>
                </>
              }
            </td>
            <td className='py-3.5 hidden lg:table-cell'>
            {teamMember.phoneNumber!=='' &&
              <>
              <div className='flex items-center'><img src={copyIcon} alt="" className='mr-1.5' onClick={()=>copyToClipboard(teamMember.phoneCode!=="" ? teamMember.phoneCode+' '+teamMember.phoneNumber : teamMember.phoneNumber)}/>{teamMember.phoneCode!=="" ? teamMember.phoneCode+' '+teamMember.phoneNumber : teamMember.phoneNumber}</div>
              </>
              }
            </td>
            <td className='py-3.5 hidden md:table-cell'>{(teamMember.dateOfBirth!=='' && teamMember.dateOfBirth!==null) ? dayjs(teamMember.dateOfBirth).format('DD / MMM / YYYY'): (teamMember.is_above_18 ? 'Over 18 years' : 'Under 18 years')}</td>          
            <td className='py-3.5 pr-2.5 md:pr-[30px]'>
              <div className="flex items-center justify-end mr-0 ml-auto">
                <button type="button" className="button-tiny edit rounded-full mr-1.5" onClick={()=>{setIsEdit(true); setOpenDetail(true);}}></button>
                <button type="button" className="button-tiny delete rounded-full mr-1.5" onClick={()=>{deleteMember()}}></button>
                <div className='relative' ref={ref}>
                    <button type="button" className="flex items-center justify-center w-[26px]" onClick={(e)=>{ setShowMoreOpt(!showMoreOpt);}}>
                        <svg width="6" height="21" viewBox="0 0 6 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.16615 5.19413C4.46227 5.19413 5.51297 4.14342 5.51297 2.84731C5.51297 1.55119 4.46227 0.500488 3.16615 0.500488C1.87004 0.500488 0.819336 1.55119 0.819336 2.84731C0.819336 4.14342 1.87004 5.19413 3.16615 5.19413Z" fill="#363F6C"/>
                            <path d="M3.16615 12.8472C4.46227 12.8472 5.51297 11.7965 5.51297 10.5004C5.51297 9.20427 4.46227 8.15356 3.16615 8.15356C1.87004 8.15356 0.819336 9.20427 0.819336 10.5004C0.819336 11.7965 1.87004 12.8472 3.16615 12.8472Z" fill="#363F6C"/>
                            <path d="M3.16615 20.5005C4.46227 20.5005 5.51297 19.4498 5.51297 18.1537C5.51297 16.8576 4.46227 15.8069 3.16615 15.8069C1.87004 15.8069 0.819336 16.8576 0.819336 18.1537C0.819336 19.4498 1.87004 20.5005 3.16615 20.5005Z" fill="#363F6C"/>
                        </svg>
                    </button>
                    <ul className={`sort-actions w-[175px] text-[14px] leading-[18px] text-black absolute overflow-hidden rounded-lg bg-white top-full right-0 text-left ${!showMoreOpt ? 'hidden' : ''}`}>                                            
                        <li><div className='cursor-pointer py-1.5' onClick={(e) => { navigate('/team/'+team._id+'/members/'+teamMember._id) }}>Purchases</div></li>
                        <li><div className='cursor-pointer py-1.5' onClick={(e) => { setShowMoreOpt(false); setShowMembershipCard(true);}}>Membership Card</div></li>
                    </ul>
                </div>
              </div>
            </td>
            </>
          }
        </tr>
        :
        <>
          <div className='flex relative px-3 mb-5 pb-5 border-b-[1px] border-slate-500 border-solid'>
            <div className='min-w-[50px] max-w-[90px]'>
              {showExportOptions ?
                <div className='flex items-center'>
                  <label key={teamMember._id} className='text-[12px] mr-3 text-black font-light cursor-pointer'>
                      <input                    
                          type="checkbox"
                          name={teamMember._id}
                          id={teamMember._id}
                          value={JSON.stringify(teamMember)}
                          className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-white mt-1 align-top float-left mr-2 cursor-pointer'
                          onChange={handleClickMembers}
                          checked={isCheckMembers.some((item)=>{ return item._id===teamMember._id })}
                      />
                  </label>
                  {teamMember.profilePicture !== '' ?  
                    <img src={teamMember.profilePicture} alt="" width={50} height={50} className="rounded-full cursor-pointer" onClick={(e)=>{if(teamMember.can_see_member_profile) setOpenDetail(true)}}/>
                    :
                    <img src={placeholderImg} alt="" width={50} height={50} className="rounded-full cursor-pointer" onClick={(e)=>{if(teamMember.can_see_member_profile) setOpenDetail(true)}}/>
                  }
                </div>
                :
                <>
                  {teamMember.profilePicture !== '' ?  
                    <img src={teamMember.profilePicture} alt="" width={50} height={50} className="rounded-full cursor-pointer" onClick={(e)=>{if(teamMember.can_see_member_profile) setOpenDetail(true)}}/>
                    :
                    <img src={placeholderImg} alt="" width={50} height={50} className="rounded-full cursor-pointer" onClick={(e)=>{if(teamMember.can_see_member_profile) setOpenDetail(true)}}/>
                  }
                </>
              }
            </div>
            <div className='flex-1 pl-3'>
              <div className='member-name uppercase cursor-pointer' onClick={(e)=>{if(teamMember.can_see_member_profile) setOpenDetail(true)}}>{teamMember.name}</div>
              <div className={`member-role uppercase is-${teamMember.user_role}`}>
                {teamMember.user_role==='group_admin' ? 'Group admin' : teamMember.user_role}
              </div>
              {teamMember.accountType==='children' ?
                <>
                  {teamMember.parents.length>0 &&
                    <>
                    <div className='text-[0.75rem]'>
                      {teamMember.parents.length===1 ? 'Parent: ': 'Parents: '}
                      {memberParents}
                    </div>
                    </>
                  }
                </>
                :
                <>
                  {teamMember.childrens.length>0 &&
                    <>
                    <div className='text-[0.75rem]'>
                      {teamMember.childrens.length===1 ? 'Child: ': 'Childs: '}
                      {memberChilds}
                    </div>
                    </>
                  }
                </>
              }
              {(teamMember.can_see_member_profile) &&
                <>
                  <div className='text-[12px] leading-[16px] mb-1.5'>
                    <a href={`mailto:${teamMember.email}`} className="truncate  text-ellipsis overflow-hidden text-[#363F6C]">{teamMember.email}</a>                
                  </div>
                  <div className='text-[12px] leading-[16px] mb-1.5 flex items-center'><div className='button-tiny date rounded-full mr-1.5'></div>{(teamMember.dateOfBirth!=='' && teamMember.dateOfBirth!==null) ? dayjs(teamMember.dateOfBirth).format('DD / MMM / YYYY'): (teamMember.is_above_18 ? 'Over 18 years' : 'Under 18 years')}</div>              
                  <div className='text-[12px] leading-[16px] flex items-center'>
                    <div className='button-tiny phone rounded-full mr-1.5'></div>{teamMember.phoneCode!=="" ? teamMember.phoneCode+' '+teamMember.phoneNumber : teamMember.phoneNumber}
                  </div>
                </>
              }
            </div>
            {teamMember.can_see_member_profile &&
            <div className="flex items-center justify-end w-[60px] absolute top-1 right-1">
                <button type="button" className="button-tiny edit rounded-full mr-1.5" onClick={()=>{setIsEdit(true); setOpenDetail(true);}}></button>
                <button type="button" className="button-tiny delete rounded-full" onClick={()=>{deleteMember()}}></button>
                <div className='relative' ref={ref}>
                    <button type="button" className="flex items-center justify-center w-[26px]" onClick={(e)=>{ setShowMoreOpt(!showMoreOpt);}}>
                        <svg width="6" height="21" viewBox="0 0 6 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.16615 5.19413C4.46227 5.19413 5.51297 4.14342 5.51297 2.84731C5.51297 1.55119 4.46227 0.500488 3.16615 0.500488C1.87004 0.500488 0.819336 1.55119 0.819336 2.84731C0.819336 4.14342 1.87004 5.19413 3.16615 5.19413Z" fill="#363F6C"/>
                            <path d="M3.16615 12.8472C4.46227 12.8472 5.51297 11.7965 5.51297 10.5004C5.51297 9.20427 4.46227 8.15356 3.16615 8.15356C1.87004 8.15356 0.819336 9.20427 0.819336 10.5004C0.819336 11.7965 1.87004 12.8472 3.16615 12.8472Z" fill="#363F6C"/>
                            <path d="M3.16615 20.5005C4.46227 20.5005 5.51297 19.4498 5.51297 18.1537C5.51297 16.8576 4.46227 15.8069 3.16615 15.8069C1.87004 15.8069 0.819336 16.8576 0.819336 18.1537C0.819336 19.4498 1.87004 20.5005 3.16615 20.5005Z" fill="#363F6C"/>
                        </svg>
                    </button>
                    <ul className={`sort-actions w-[175px] text-[14px] leading-[18px] text-black absolute overflow-hidden rounded-lg bg-white top-full right-0 text-left ${!showMoreOpt ? 'hidden' : ''}`}>                                            
                        <li><div className='cursor-pointer py-1.5' onClick={(e) => { navigate('/team/'+team._id+'/members/'+teamMember._id) }}>Purchases</div></li>
                        <li><div className='cursor-pointer py-1.5' onClick={(e) => { setShowMoreOpt(false); setShowMembershipCard(true);}}>Membership Card</div></li>
                    </ul>
                </div>
            </div>
            }
          </div>
          {openDetail &&
            <MemberDetail setShowForm={setOpenDetail} team={team} teamMemberID={teamMember._id} memberSub={teamMember.sub} refreshMembers={refreshMembers} isEdit={isEdit} setIsEdit={setIsEdit} deleteMember={deleteMember} />
          }
        </>
        }
        {showMemberShipCard &&
          <TeamMembershipCards teamId={team._id} membersub={teamMember.sub} token={token} setShowForm={setShowMembershipCard} />
        }
      </>
      :
      <>
        <tr  className='border-b border-black-100/10'>
          {team.role === 'admin' &&
          <td className='py-3.5 pl-2.5 md:pl-[30px]'>
              <label key={teamMember._id} className='text-[12px] mr-3 text-black font-light cursor-pointer'>
                  <input                    
                      type="checkbox"
                      name={teamMember._id}
                      id={teamMember._id}
                      value={teamMember._id}
                      className='checkbox-field appearance-none h-4 w-4 rounded-sm bg-white mt-1 align-top float-left mr-2 cursor-pointer'
                      onChange={handleClickDelMembers}
                      checked={isCheckDelInvites.some((item)=>{ return item===teamMember._id })}
                  />
              </label>
          </td>
          }
          <td className='py-3.5'>
            {teamMember.firstName} {teamMember.lastName}
          </td>
          <td className='py-3.5'>
            <a href={`mailto:${teamMember.email}`}>{teamMember.email}</a>
          </td>
          <td className='py-3.5'>          
              {dayjs(teamMember.date).format('DD/MM/YYYY')}
          </td>          
          {team.role === 'admin' &&
            <td className='py-3.5 pr-2.5 md:pr-[30px]'>
              <div className="flex items-center justify-end">
                <button type="button" className="button-tiny delete rounded-full" onClick={()=>{deleteInviteMember()}}></button>
              </div>
            </td>
          }
        </tr>
      </>
    );
}
export default MembersRow;