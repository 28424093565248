import { useState } from "react";
import * as DOMPurify from 'dompurify';
import YellowCard from "../../../assets/images/yellow-card.svg";
import RedCard from "../../../assets/images/red-card.svg";
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(relativeTime);

const LiveScoreCard=({score_details, live_stream_link})=>{
    const [showLiveScorePopup, setShowLiveScorePopup] = useState(false);

    const LiveScoreCardPopup=({setShowPopup})=>{
        return(
            <div className="popup-form">
                <div className="w-full rounded-xl bg-white p-5 max-w-[748px] relative">
                    <button type="button" className="close-popup" onClick={(e)=>{setShowPopup(false)}}><span>close</span></button>
                    <div className="rounded-xl bg-[var(--color-darkblue)] px-5 pt-5 pb-10 max-w-[708px] text-white relative mb-5">
                        <div className="sm:flex w-full sm:justify-between sm:items-center mb-5">
                            <div className="w-full sm:w-auto flex gap-3">
                                <span className="bg-red-500 text-sm/3 py-2 px-4 rounded-full">{score_details.game_status}</span>
                                {live_stream_link!=="" &&
                                    <a href={live_stream_link} target="_blank" rel="noreferrer">
                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.5909 19.8873V10.1127C11.5911 9.99045 11.6242 9.87046 11.6868 9.76535C11.7493 9.66024 11.8389 9.57387 11.9463 9.51531C12.0536 9.45675 12.1748 9.42814 12.297 9.43249C12.4192 9.43684 12.538 9.47398 12.6409 9.54002L20.2446 14.4259C20.3409 14.4876 20.4201 14.5725 20.4749 14.6728C20.5298 14.7732 20.5586 14.8857 20.5586 15C20.5586 15.1144 20.5298 15.2269 20.4749 15.3272C20.4201 15.4275 20.3409 15.5124 20.2446 15.5741L12.6409 20.4614C12.538 20.5274 12.4192 20.5646 12.297 20.5689C12.1748 20.5733 12.0536 20.5446 11.9463 20.4861C11.8389 20.4275 11.7493 20.3412 11.6868 20.2361C11.6242 20.1309 11.5911 20.0109 11.5909 19.8887V19.8873Z" fill="#FF0000"/>
                                            <path d="M0 15C0 6.71591 6.71591 0 15 0C23.2841 0 30 6.71591 30 15C30 23.2841 23.2841 30 15 30C6.71591 30 0 23.2841 0 15ZM15 2.04545C11.5642 2.04545 8.2692 3.4103 5.83975 5.83975C3.4103 8.2692 2.04545 11.5642 2.04545 15C2.04545 18.4358 3.4103 21.7308 5.83975 24.1602C8.2692 26.5897 11.5642 27.9545 15 27.9545C18.4358 27.9545 21.7308 26.5897 24.1602 24.1602C26.5897 21.7308 27.9545 18.4358 27.9545 15C27.9545 11.5642 26.5897 8.2692 24.1602 5.83975C21.7308 3.4103 18.4358 2.04545 15 2.04545Z" fill="#FF0000"/>
                                        </svg>
                                    </a>
                                }
                            </div>
                            <div className="w-full sm:w-auto text-xs">
                                Time Remaining: <strong>{score_details?.time_remaining?.time}</strong>
                            </div>
                        </div>
                        <div className="md:flex md:justify-between md:items-center">
                            <div className="flex gap-4 items-center">
                                <span className="font-semibold text-sm">{score_details.home_team_name}</span>
                                <img src={score_details.home_team_logo} alt={score_details.home_team_name} width={60} height={60} className="rounded-full" />
                            </div>
                            <div className="font-bold text-3xl">
                                {score_details.home_team_score} - {score_details.away_team_score}
                            </div>
                            <div className="flex gap-4 items-center mb-5">
                                <img src={score_details.away_team_logo} alt={score_details.away_team_name} width={60} height={60} className="rounded-full" />
                                <span className="font-semibold text-sm">{score_details.away_team_name}</span>                    
                            </div>
                        </div>
                    </div>
                    {(score_details.is_there_yellow_card==="true" || score_details.is_there_red_card==="true") &&
                        <div className="md:flex md:justify-between md:items-center text-xs">
                            {score_details.is_there_yellow_card==="true" &&
                                <>
                                    <div className="flex gap-4 items-center mb-5 md:mb-0 py-5">
                                        <span>{score_details.how_many_yellow_card}</span>
                                        <img src={YellowCard} alt="Yellow Card" width={21} height={19}/>
                                        <span>{score_details.yellow_card_player_name}</span>
                                    </div>
                                </>
                            }
                            {(score_details.is_there_yellow_card==="true" && score_details.is_there_red_card==="true") &&
                                <span className="hidden md:block w-[1px] h-12.5 bg-gray-300"></span>
                            }
                            {score_details.is_there_red_card==="true" &&
                                <>
                                    <div className="flex gap-4 items-center mb-5 md:mb-0 py-5">
                                        <span>{score_details.how_many_red_card}</span>
                                        <img src={RedCard} alt="Red Card" width={21} height={19}/>
                                        <span>{score_details.red_card_player_name}</span>
                                    </div>
                                </>
                            }
                        </div>
                    }
                    {score_details.is_there_substitutes==="true" &&
                        <div>
                            <div className="flex justify-between text-xs mt-5">
                                <div className="w-1/2">Player Substituted Off</div>
                                <div className="w-auto">Player Substituted On</div>
                            </div>
                            {score_details.substitutes.map((substitute,index)=>{
                                return(
                                    <div className="flex justify-between text-base font-semibold border-b border-gray-300 last-of-type:border-b-0 py-3" key={index}>
                                        <div className="w-1/2">{substitute.substitute_off}</div>
                                        <div className="w-auto">{substitute.substitute_on}</div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                    {(score_details.key_update !== undefined && score_details.key_update !== '') &&
                        <div className='text-sm font-light mt-6' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(score_details.key_update) }} />
                    }
                    <div className="text-right text-sm">
                        <em>Last Updated {dayjs(score_details.last_update_at).fromNow()}</em>
                    </div>
                </div>
            </div>
        )        
    }
    
    return(
    <>
        <div className="rounded-xl bg-[var(--color-darkblue)] px-5 pt-5 pb-10 max-w-[708px] text-white relative mb-18">
            <div className="sm:flex w-full sm:justify-between sm:items-center mb-5">
                <div className="w-full sm:w-auto flex gap-3">
                    <span className="bg-red-500 text-sm/3 py-2 px-4 rounded-full">{score_details.game_status}</span>
                    {live_stream_link!=="" &&
                        <a href={live_stream_link} target="_blank" rel="noreferrer">
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.5909 19.8873V10.1127C11.5911 9.99045 11.6242 9.87046 11.6868 9.76535C11.7493 9.66024 11.8389 9.57387 11.9463 9.51531C12.0536 9.45675 12.1748 9.42814 12.297 9.43249C12.4192 9.43684 12.538 9.47398 12.6409 9.54002L20.2446 14.4259C20.3409 14.4876 20.4201 14.5725 20.4749 14.6728C20.5298 14.7732 20.5586 14.8857 20.5586 15C20.5586 15.1144 20.5298 15.2269 20.4749 15.3272C20.4201 15.4275 20.3409 15.5124 20.2446 15.5741L12.6409 20.4614C12.538 20.5274 12.4192 20.5646 12.297 20.5689C12.1748 20.5733 12.0536 20.5446 11.9463 20.4861C11.8389 20.4275 11.7493 20.3412 11.6868 20.2361C11.6242 20.1309 11.5911 20.0109 11.5909 19.8887V19.8873Z" fill="#FF0000"/>
                                <path d="M0 15C0 6.71591 6.71591 0 15 0C23.2841 0 30 6.71591 30 15C30 23.2841 23.2841 30 15 30C6.71591 30 0 23.2841 0 15ZM15 2.04545C11.5642 2.04545 8.2692 3.4103 5.83975 5.83975C3.4103 8.2692 2.04545 11.5642 2.04545 15C2.04545 18.4358 3.4103 21.7308 5.83975 24.1602C8.2692 26.5897 11.5642 27.9545 15 27.9545C18.4358 27.9545 21.7308 26.5897 24.1602 24.1602C26.5897 21.7308 27.9545 18.4358 27.9545 15C27.9545 11.5642 26.5897 8.2692 24.1602 5.83975C21.7308 3.4103 18.4358 2.04545 15 2.04545Z" fill="#FF0000"/>
                            </svg>
                        </a>
                    }
                </div>
                <div className="w-full sm:w-auto text-xs">
                    Time Remaining: <strong>{score_details?.time_remaining?.time}</strong>
                </div>
            </div>
            <div className="md:flex md:justify-between md:items-center">
                <div className="flex gap-4 items-center">
                    <span className="font-semibold text-sm">{score_details.home_team_name}</span>
                    <img src={score_details.home_team_logo} alt={score_details.home_team_name} width={60} height={60} className="rounded-full" />
                </div>
                <div className="font-bold text-3xl">
                    {score_details.home_team_score} - {score_details.away_team_score}
                </div>
                <div className="flex gap-4 items-center mb-5">
                    <img src={score_details.away_team_logo} alt={score_details.away_team_name} width={60} height={60} className="rounded-full" />
                    <span className="font-semibold text-sm">{score_details.away_team_name}</span>                    
                </div>
            </div>
            <div className="flex justify-center absolute w-full left-0 -bottom-7">
                <button className='button !text-lg p-2.5 h-[54px] w-[220px]' type="button" onClick={()=>{setShowLiveScorePopup(!showLiveScorePopup)}}>View More</button>
            </div>
        </div>
        {showLiveScorePopup &&
            <LiveScoreCardPopup setShowPopup={setShowLiveScorePopup} />
        }
    </>
    )
}
export default LiveScoreCard;